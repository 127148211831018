import { api } from './main';

export default {

  getAppointments() {
    return api({
      method: 'GET',
      url: '/api/v1/agendamento/',
      data: null,
    });
  },

  getAppointmentData(appointmentId) {
    return api({
      method: 'GET',
      url: `/api/v1/agendamento/${appointmentId}`,
      data: null,
    });
  },

  cancelAppointment(appointmentId) {
    return api({
      method: 'POST',
      url: `/api/v1/agendamento/${appointmentId}/cancelar`,
      data: null,
    });
  },
};
