import Keycloak from 'keycloak-js';
import store from '.././store';

var url = process.env.VUE_APP_KEYCLOAK_URL;

let userInfo = {
    name: '',
    email: '',
    authenticated: false,
    userToken: '',
    keycloak: null,
};

const keycloak = new Keycloak({
  url: url,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENTID,
});

export default {
  async initKeycloak(onload) {
    keycloak.onTokenExpired = function () {
      keycloak.updateToken(300).then(async function() {
        store.commit('KEYCLOAK_UPDATE_USER_TOKEN', keycloak.token);
      }).catch(async function () {
        userInfo.userToken = null;
        userInfo.authenticated = false;
        await keycloak.logout();
      });
    };
    if(onload === 'check-sso'){
      await keycloak.init({
        onLoad: onload,
        silentCheckSsoRedirectUri: `${window.location.origin}/index.html`,
        checkLoginIframe: true,
        pkceMethod: 'S256',
      })
      .then((auth)=>{
        if(auth){
          userInfo = {
            name: keycloak.idTokenParsed.name,
            email: keycloak.idTokenParsed.email,
            phone: keycloak.idTokenParsed.phone_number?.replace(/(\d{2})?(\d{5})?(\d{4})/, '($1) $2-$3'),
            birthdate: keycloak.idTokenParsed.birth_date,
            cpf: keycloak.idTokenParsed.preferred_username,
            avatar: keycloak.idTokenParsed.avatar,
            authenticated: keycloak.authenticated,
            userToken: keycloak.token,
            keycloak: keycloak,
            userId: keycloak.idTokenParsed.sub,
          };
        }else{
          userInfo.authenticated = keycloak.authenticated;
          userInfo.keycloak = keycloak;
        }
      })
      .catch(function() {
        alert('Ocorreu um erro ao inicializar o sistema');
      });
    }else{
      await keycloak.init({
        checkLoginIframe: true,
        pkceMethod: 'S256',
      })
      .then((auth) => {
        if(auth){
          userInfo = {
            name: keycloak.idTokenParsed.name,
            email: keycloak.idTokenParsed.email,
            phone: keycloak.idTokenParsed.phone_number?.replace(/(\d{2})?(\d{5})?(\d{4})/, '($1) $2-$3'),
            birthdate: keycloak.idTokenParsed.birth_date,
            cpf: keycloak.idTokenParsed.preferred_username,
            avatar: keycloak.idTokenParsed.avatar,
            authenticated: keycloak.authenticated,
            userToken: keycloak.token,
            keycloak: keycloak,
            userId: keycloak.idTokenParsed.sub,
          };
        }else{
          userInfo.authenticated = keycloak.authenticated;
          userInfo.keycloak = keycloak;
        }
      })
      .catch(function(e) {
          alert(`failed to initialize ${e}`);
      });
    }

    return userInfo;
  },
};