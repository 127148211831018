/* eslint-disable */

export const hotjarMixin = {
    async mounted() {
      await this.setHotjarCode();
    },
    methods: {
      setHotjarCode() {
        if(process.env.NODE_ENV !== 'production')
          return;

        const USER_ID = process.env.VUE_APP_HOTJAR_ID ?? '';
        let head = document.getElementsByTagName('head')[0];
        let script = document.createElement('script');

        script.type = 'text/javascript';
        script.text = this.hotjarCode(USER_ID);
        head.appendChild(script);
      },
      hotjarCode(USER_ID) {
        return `(function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${USER_ID},hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
      }
    }
  };