<template>
  <div
    id="app"
  >
    <transition
      name="slide-fade"
      mode="out-in"
    >
      <router-view />
    </transition>
    <privacy-policy />
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import { activeCommands } from '@/helpers';
import PrivacyPolicy from './components/common/PrivacyPolicy.vue';
import { hotjarMixin } from '@/mixins/hotjarMixin';

export default {
  name: 'App',
  components: { PrivacyPolicy },
  mixins: [ hotjarMixin ],
  computed: {
    privacyPoliciesURL() {
      switch (process.env.VUE_APP_KEYCLOAK_REALM) {
        case 'Uppersoft':
          return 'https://upcities.app/politica-privacidade-pmvv';
        case 'pmvv':
          return 'https://upcities.app/politica-privacidade-pmvv';
        case 'pmcariacica':
          return 'https://upcities.app/politica-privacidade-cariacica';
        case 'pmci':
          return 'https://upcities.app/politica-privacidade-cachoeiro-itapemirim';
        default:
          return '#';
      }
    },
    placeName() {
      switch (process.env.VUE_APP_KEYCLOAK_REALM) {
        case 'Uppersoft':
          return 'Upcities';
        case 'pmvv':
          return 'Vila Velha';
        case 'pmcariacica':
          return 'Cariacica';
        case 'pmci':
          return 'Cachoeiro de Itapemirim';
        default:
          return '#';
      }
    }
  },

  metaInfo() {
    return {
      titleTemplate: `%s | Agendamento de ${this.placeName}`,

      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Agende serviços com muita praticidade.'
        },

        // OpenGraph
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'Agendamento'
        },
        {
          vmid: 'og:site_name',
          property: 'og:site_name',
          content: 'Agendamento'
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'website'
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: `${window.location.origin}`
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: require(`@/assets/theme/${
            process.env.VUE_APP_KEYCLOAK_REALM === 'Uppersoft'
              ? 'upcities'
              : process.env.VUE_APP_KEYCLOAK_REALM
          }/icons/og/meta.jpg`)
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: 'Agende serviços com muita praticidade.'
        },

        // Twitter
        {
          vmid: 'twitter:card',
          name: 'twitter:card',
          content: 'Agende serviços com muita praticidade.'
        },
        {
          vmid: 'twitter:site',
          name: 'twitter:site',
          content: `${window.location.origin}`
        },
        {
          vmid: 'twitter:title',
          name: 'twitter:title',
          content: 'Agendamento'
        },
        {
          vmid: 'twitter:description',
          name: 'twitter:description',
          content: 'Agende serviços com muita praticidade.'
        },
        {
          vmid: 'twitter:image',
          name: 'twitter:image',
          content: require(`@/assets/theme/${
            process.env.VUE_APP_KEYCLOAK_REALM === 'Uppersoft'
              ? 'upcities'
              : process.env.VUE_APP_KEYCLOAK_REALM
          }/icons/og/meta.jpg`)
        },
        {
          vmid: 'twitter:image:src',
          name: 'twitter:image:src',
          content: require(`@/assets/theme/${
            process.env.VUE_APP_KEYCLOAK_REALM === 'Uppersoft'
              ? 'upcities'
              : process.env.VUE_APP_KEYCLOAK_REALM
          }/icons/og/meta.jpg`)
        },

        {
          vmid: 'name',
          itemprop: 'name',
          content: 'Agendamento'
        },
        {
          vmid: 'description',
          itemprop: 'description',
          content: 'Agende serviços com muita praticidade.'
        },
        {
          vmid: 'image',
          itemprop: 'image',
          content: require(`@/assets/theme/${
            process.env.VUE_APP_KEYCLOAK_REALM === 'Uppersoft'
              ? 'upcities'
              : process.env.VUE_APP_KEYCLOAK_REALM
          }/icons/og/meta.jpg`)
        }
      ],

      link: [
        {
          vmid: 'api',
          rel: 'preconnect',
          href: `${process.env.VUE_APP_API_ROUTE}`
        },
        {
          vmid: 'conta',
          rel: 'preconnect',
          href: `${process.env.VUE_APP_KEYCLOAK_URL}`
        },
        {
          vmid: 'minha-conta',
          rel: 'preconnect',
          href: `${process.env.VUE_APP_MINHACONTA_ROUTE}`
        },
        {
          vmid: 'canonical',
          rel: 'canonical',
          href: `${window.location.origin}`
        },
        {
          vmid: 'favicon',
          rel: 'icon',
          href: require(`@/assets/theme/${
            process.env.VUE_APP_KEYCLOAK_REALM === 'Uppersoft'
              ? 'upcities'
              : process.env.VUE_APP_KEYCLOAK_REALM
          }/icons/favicon/favicon.png`)
        }
      ]
    };
  },

  async created() {
    this.appendUserwayAccessibilityBar();
  },

  mounted() {
    setTimeout(() => {
      let url = document.getElementById('policies-link');

      url === null ? '' : (url.href = this.privacyPoliciesURL);
    }, 2500);

    this.includeClarity();
    this.includeAnalytics();
    this.includeGoogleMapsKey();
    this.activeCommands();
  },

  methods: {
    activeCommands() {
      return activeCommands();
    },
    includeClarity() {
      let importScript = document.createElement('script');

      importScript.type = 'text/javascript';

      if (process.env.VUE_APP_CLARITY_TOKEN) {
        importScript.onload = (function(c, l, a, r, i, t, y) {
          c[a] =
            c[a] ||
            function() {
              (c[a].q = c[a].q || []).push(arguments);
            };
          t = l.createElement(r);
          t.async = 1;
          t.src = `https://www.clarity.ms/tag/${i}`;
          y = l.getElementsByTagName(r)[0];
          y.parentNode.insertBefore(t, y);
        })(window, document, 'clarity', 'script', process.env.VUE_APP_CLARITY_TOKEN);
      }
    },
    includeAnalytics() {
      let importNode = document.createElement('script');
      let importScript = document.createElement('script');

      importNode.type = 'text/javascript';
      importScript.type = 'text/javascript';

      importNode.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.VUE_APP_ANALYTICS_GTAG}`;
      importNode.async = true;

      importScript.onload = function() {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag('js', new Date());

        gtag('config', `'${process.env.VUE_APP_ANALYTICS_GTAG}'`);
      };
    },
    includeGoogleMapsKey() {
      let Script = document.createElement('script');

      Script.type = 'text/javascript';

      Script.setAttribute('defer', 'defer');

      Script.setAttribute('src', `https://maps.google.com/maps/api/js?key=${process.env.VUE_APP_MAPS_KEY}&libraries=places`);
      document.body.appendChild(Script);
      Script.async = true;
    },
    appendUserwayAccessibilityBar() {
      const uway = document.createElement('script');

      uway.setAttribute('type', 'text/javascript');
      uway.setAttribute(
        'src',
        'https://cdn.userway.org/widget.js'
      );
      uway.setAttribute('data-account', process.env.VUE_APP_USERWAY_ACCOUNT);
      document.querySelector('body').appendChild(uway);
    },
  }
};
</script>

<style lang="scss">
div[vw] {
  z-index: $z-vlibras-button !important;
}

/**
   *
   * Normalize from Tailwind
   * https://unpkg.com/tailwindcss@1.1.4/dist/base.css
   *
   */
@import "@/assets/sass/base/_normalize.scss";

/**
   *
   * Base
   *
   */
@import "@/assets/sass/base/_container.scss";
@import "@/assets/sass/base/_fonts.scss";
@import "@/assets/sass/base/_transitions.scss";
@import "@/assets/sass/base/_variables.scss";
@import "@/assets/sass/base/_utils.scss";

/**
   *
   * Project
   *
   */
@import "@/assets/sass/project/_buttons.scss";
@import "@/assets/sass/project/_radio.scss";
@import "@/assets/sass/project/_form.scss";
@import "@/assets/sass/project/_typography.scss";
@import "@/assets/sass/project/_datepicker.scss";
@import "@/assets/sass/project/_autocomplete.scss";

html {
  scroll-behavior: smooth;
  min-height: 100%;

  body {
    font-family: $montserrat-regular;
    color: $black;
    font-weight: $font-regular;
    font-size: $text-base;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $main-bg;
    min-height: 100%;

    //responsive svg
    svg {
      max-width: 100%;
      height: auto;
    }

    #app {
      min-height: 100vh;

      .loading-page {
        background-color: rgba(darken(saturate($primary, 100%), 15%), 0.75);
        background-blend-mode: luminosity;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center right;
        min-height: 100vh;
        min-width: 100vw;
        display: flex;
        justify-content: center;
        padding: 7.8125rem 7.1875rem; //125px 115px

        @include screen(phone-only) {
          padding: 2rem;
        }

        .message {
          height: 100%;
          width: auto;
        }

        svg {
          color: $white;
          fill: $white;
        }
      }
    }

    &.blocked {
      overflow: hidden;
    }

    &.fixed {
      position: fixed;
    }

    .uwy {
      z-index: $z-userway-button;

      & .uai {
        left: 10px;
        top: $header-mobile-height + $subheader-mobile-height;

        @include screen(tablet-big-up) {
          left: 0;
          top: $header-desktop-height + $subheader-desktop-height;
        }
      }
    }
  }
}

.modal-override {
  border-radius: 0.5rem !important;
}

.grecaptcha-badge,
.access-button {
  @media print {
    display: none !important;
  }
}

.switch {
  display: inline-block;
  width: 60px;
  height: 34px;
  position: fixed;
  top: 70%;
  right: 0;
  z-index: 99999;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider::before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.high-contrast:checked + .slider {
  @include make-accessible($primary, $tertiary-accessibility, background);
}

.high-contrast:focus + .slider {
  box-shadow: 0 0 1px $primary;
}

.high-contrast:checked + .slider::before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round::before {
  border-radius: 50%;
}
</style>
