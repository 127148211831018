
import api from '@/api/consult';

export default {
  state: {
    appointments: null,
    appointment: null,
    cancelAppointment: null,
    errorMessage: null,
  },

  getters: {
    userAppointments: (state) => state.appointments,
    userAppointmentData: (state) => state.appointment,
    userAppointmetCanceled: (state) => state.cancelAppointment,
    messageError: (state) => state.errorMessage,
  },

  actions: {
    async getUserAppointments({ commit }) {
      try {
        const res = await api.getAppointments();

        commit('SET_USER_APPOINTMENTS', res.data);

        return Promise.resolve(res);
      } catch (e) {
        commit('SET_USER_APPOINTMENTS', null);

        return Promise.reject(e);
      }
    },

    async getUserAppointmentData({ commit }, appointmentId) {
      try {
        const res = await api.getAppointmentData(appointmentId);

        commit('SET_USER_APPOINTMENT', res.data);

        return Promise.resolve(res);
      } catch (e) {
        commit('SET_USER_APPOINTMENT', null);

        return Promise.reject(e);
      }
    },

    async cancelUserAppointment({ commit }, appointmentId) {
      try {
        const res = await api.cancelAppointment(appointmentId);

        commit('CANCEL_USER_APPOINTMENT', res.message);

        return Promise.resolve(res);
      } catch (e) {
        commit('CANCEL_USER_APPOINTMENT', null);

        return Promise.reject(e);
      }
    },
  },

  mutations: {
    SET_USER_APPOINTMENTS(state, payload){
        state.appointments = payload;
    },
    SET_USER_APPOINTMENT(state, payload){
        state.appointment = payload;
    },
    CANCEL_USER_APPOINTMENT(state, payload) {
      state.cancelAppointment = payload;
    },
    SET_ERROR_MESSAGE(state, payload){
      state.errorMessage = payload;
    }
  }
};
