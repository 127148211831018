import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import store from '@/store/index';

Vue.use(VueRouter);
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true,
});

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: {
          requiresAuth: false,
        },
      },
    ]
  },
  {
    path: '/agendamento',
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'Appointment',
        meta: {
          title: 'Agendamento'
        },
        component: () => import(/* webpackChunkName: "Appointment" */ '../views/schedule/make/Appointment.vue'),
      },
      {
        path: 'informacoes',
        name: 'AppointmentGeneralInfo',
        component: () => import(/* webpackChunkName: "AppointmentGeneralInfo" */ '../views/schedule/make/AppointmentGeneralInfo.vue'),
      },
    ]
  },
  {
    path: '/consulta-agendamento',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'ConsultaAgendamento',
        props: (route) => ({ cancelado: Boolean(route.query.cancelado) }),
        component: () => import(/* webpackChunkName: "appointment-index" */ '../views/schedule/consult/Index.vue'),
      },
      {
        path: ':appointmentId',
        name: 'appointmentSingle',
        props: (route) => ({ appointmentId: String(route.params.appointmentId) }),
        component: () => import(/* webpackChunkName: "appointment-detail" */ '../views/schedule/consult/Single.vue'),
      },
    ],
  },
  {
    path: '/faq',
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'FAQ',
        component: () => import(/* webpackChunkName: "Error" */ '../views/FAQ.vue'),

      },
    ]
  },
  {
    path: '/avaliacao',
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'Rating',
        component: () => import(/* webpackChunkName: "Error" */ '../views/rating/index.vue'),
      },
      {
        path: 'sucesso',
        name: 'Success',
        component: () => import(/* webpackChunkName: "Error" */ '../views/rating/Success.vue'),
      },
    ]
  },
  {
    path: '/acessibilidade',
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'Accessibility',
        component: () => import(/* webpackChunkName: "Accessibility" */ '../views/Accessibility.vue'),

      },
    ]
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: 'erro',
        name: 'Error',
        component: () => import(/* webpackChunkName: "Error" */ '../views/ErrorPage.vue'),

      },
    ]
  },
  // --- Not found route
  {
    path: '*',
    component: () => import(/* webpackChunkName: "app-error-page" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'ErrorNotFound',
        component: () => import(/* webpackChunkName: "Error" */ '../views/ErrorNotFound.vue'),
      },
    ]
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  const redirectQuery = {};

  redirectQuery.redirect = to.fullPath;
   // Checking if the route requires auth
  if (to.matched.some((record) => record.meta.requiresAuth) && !store.getters.userToken) {
    store.dispatch('loginKeycloak', `${window.location.origin}`);
  }
  window.scrollTo(0, 0);
});

export default router;
