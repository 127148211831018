import { surveyApi } from './main';

export default {
  getSurvey(surveyID) {
    if(!surveyID) return null;

      return surveyApi({
        method: 'GET',
        url: `/api/v1/survey/${surveyID}`,
        data: null,
      });
  },

  postSurvey(data, surveyID) {
    if(!surveyID) return null;

      return surveyApi({
        method: 'POST',
        url: `/api/v1/survey/${surveyID}/answers`,
        data,
      });
  }
};