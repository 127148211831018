import api from '@/api/search';

export default {
  state: {
    searchData: null,
    serviceNeedsUpdate: false,
  },
  getters: {
    searchData: (state) => state.searchData,
    serviceNeedsUpdate: (state) => state.serviceNeedsUpdate
  },
  mutations: {
    SET_SEARCH_DATA(state, data) {
      state.searchData = data;
    },
    UPDATE_SERVICES_STEP(state, data) {
      state.serviceNeedsUpdate = data;
    }
  },
  actions: {
    async getSearchData({ commit }) {
      try {
        const res = await api.getSearchData();

        commit('SET_SEARCH_DATA', res.data.servicos);

        return Promise.resolve(res);
      } catch (error) {
        commit('SET_SEARCH_DATA', null);

        return Promise.reject(error);
      }
    },
    async updateServicesStep({ commit }, payload) {
      commit('UPDATE_SERVICES_STEP', payload);
    }
  },
};
