<template>
  <div
    v-if="show"
    id="policy"
    class="policy"
  >
    <div class="policy__content">
      <p class="policy__text">
        Esta página do site da Prefeitura {{ townName }} usa Cookies Operacionais de Sessão
        para o funcionamento do sistema,que podem armazenar seus dados pessoais indiretamente. Conforme detalhado no
        <a
          class="policy__link"
          rel="noopener noreferrer"
          target="_blank"
          :href="$t('POLICY.ref')"
        >
          AVISO DE PRIVACIDADE
        </a>
        e de acordo com o Art. 5 da LGPD (Lei 13.709/2018) solicitamos seu CONSENTIMENTO para prosseguir.
        Sua rejeição pode implicar no não funcionamento das tecnologias do site. Você aceita os Cookies de Sessão?
      </p>
      <button
        id="policy-accept-button"
        class="policy__button"
        @click="acceptTherms()"
      >
        SIM
      </button>
      <button
        id="policy-decline-button"
        class="policy__button"
        @click="acceptTherms()"
      >
        NÃO
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  data() {
    return {
      show: true,
      version: 'v0.2.0',
    };
  },
  computed: {
    townName() {
      switch(process.env.VUE_APP_KEYCLOAK_REALM) {
        case 'pmvv':
          return 'de Vila Velha';
        case 'pmcariacica':
          return 'de Cariacica';
        case 'pmci':
          return 'de Cachoeiro de Itapemirim';
        default :
          return 'Upcities';
      }
    }
  },
  created() {
    if(localStorage.getItem('policy-accept'))
      this.show = false;
  },
  methods: {
    acceptTherms() {
      localStorage.setItem('policy-accept', this.version);
      this.show = false;

      return;
    }
  }
};
</script>

<style lang="scss" scoped>
  $z-policy-banner: $layer-4 + $below;

  .policy {
    width: 100vw;
    position: fixed;
    z-index: $z-policy-banner;
    left: 0;
    bottom: 0;
    font-family: sans-serif;
    background-color: rgba(21, 21, 21, 0.95);
    color: #fff;
    display: flex;
  }

  .policy .policy__content {
    width: 100%;
    padding: 24px 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .policy .policy__text {
    flex: 1;
    color: white;
    line-height: 1.6;
    font-size: 16px;
    margin: 0;
  }

  .policy .policy__link {
    outline: none;
    color: #fff;
    text-decoration: underline;
  }

  .policy .policy__button {
    background-color: #fff;
    color: #000;
    border: 0;
    outline: 0;
    margin: 0;
    padding: 12px 22px;
    font-weight: bold;
    border-radius: 6px;
    transition: 0.2s all ease-out;
    font-size: 12px;
    margin-right: 0.5rem;
  }

  .policy .policy__button:hover {
    transform: scale(1.1);
    transition: 0.2s all ease-in;
    cursor: pointer;
  }

  .policy.policy--accepted {
    animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }

  @media (max-width: 768px) {
    .policy .policy__content {
      flex-direction: column;
    }

    .policy .policy__text {
      font-size: 14px;
    }

    .policy .policy__button {
      width: 100%;
      margin-top: 16px;
      font-size: 12px;
    }
  }

  @-webkit-keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(1000px);
      transform: translateY(1000px);
      opacity: 0;
    }
  }

  @keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(1000px);
      transform: translateY(1000px);
      opacity: 0;
    }
  }
</style>