import api from '@/api/survey';

export default {
  state: {
    survey: null,
    surveyAppointmentId: null,
    surveyResponse: null
  },
  getters: {
    survey: (state) => state.survey,
    surveyAppointmentId: (state) => state.surveyAppointmentId,
  },
  actions: {
    async getSurvey({ commit, dispatch }, surveyID) {
      try {
        const res = await api.getSurvey(surveyID);

        if(res) {
          commit('SET_SURVEY', res.data);
        } else {
          dispatch('resetSurveyFields');
        }

        return Promise.resolve(res);
      } catch (error) {
        commit('SET_SURVEY', null);

        return Promise.reject(error);
      }
    },
    async postSurvey({ commit }, { answers, surveyID } ) {
      try {
        const res = await api.postSurvey(answers, surveyID);

        commit('SET_SURVEY_RESPONSE', res.data);

        return Promise.resolve(res);
      } catch (error) {
        commit('SET_SURVEY_RESPONSE', null);

        return Promise.reject(error);
      }
    },
    saveSurveyAppointmentId({ commit }, surveyAppointmentId) {
      commit('SET_SURVEY_APPOINTMENT_ID', surveyAppointmentId);
    },
    resetSurveyFields({ commit }) {
      commit('RESET_SURVEY_FIELDS');
    }
  },
  mutations: {
    SET_SURVEY(state, survey) {
      state.survey = survey;
    },
    SET_SURVEY_APPOINTMENT_ID(state, payload) {
      state.surveyAppointmentId = payload;
    },
    SET_SURVEY_RESPONSE(state, surveyResponse) {
      state.surveyResponse = surveyResponse;
    },
    RESET_SURVEY_FIELDS(state) {
      state.survey = null;
      state.surveyAppointmentId = null;
      state.surveyResponse = null;
    },
  }
};