import Vue from 'vue';
import Vuex from 'vuex';
import keycloak from './modules/keycloak';
import appointment from './modules/appointment';
import consult from './modules/consult';
import survey from './modules/survey';
import search from './modules/search';
import errorModal from './modules/errorModal';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    keycloak,
    appointment,
    consult,
    survey,
    search,
    errorModal
  },
  plugins: [
    createPersistedState({
      key: `Agendamento${keycloak.getters.userToken}`,
    }),
  ],
});
