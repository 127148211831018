
/**
 * Verifica se um CPF é válido
 *
 * @param string número do CPF para validação
 * @return boolean TRUE para CPF válido e FALSE para cpf inválido
*/
export function cpfValidator(cpfNumber) {
  let sum;

  let rest;

  sum = 0;

  const cpf = cpfNumber.replace(/\D+/g, '');

  if (/^(.)\1+$/.test(cpf)) {
    return false;
  }

  for (let i = 1; i <= 9; i += 1) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  }

  rest = (sum * 10) % 11;

  if ((rest === 10) || (rest === 11)) {
    rest = 0;
  }

  if (rest !== parseInt(cpf.substring(9, 10), 10)) {
    return false;
  }

  sum = 0;

  for (let i = 1; i <= 10; i += 1) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
  }

  rest = (sum * 10) % 11;

  if ((rest === 10) || (rest === 11)) {
    rest = 0;
  }

  if (rest !== parseInt(cpf.substring(10, 11), 10)) {
    return false;
  }

  return true;
}

export function susValidator(susNumber) {
  var cns = susNumber.replace(/\./g, '');

  var validaTamanho = cns.length === 15;
  var validaInicio = ['1', '2', '7', '8', '9'].includes(cns[0]);

  if(validaTamanho && validaInicio){
    var soma = 0;

    //CNS Iniciados em 7, 8 ou 9
    if(['7', '8', '9'].includes(cns[0])) {
      soma = cns.split('').reduce((total, value, index) => total + (value * (15 - index)), 0);

      return soma % 11 === 0;
    } else {
      //CNS Iniciados em 1, 2
      var pis = cns.substring(0,11);

      soma = pis.split('').reduce((total, value, index) => total + (value * (15 - index)), 0);
      var resto = soma % 11;
      var dv = resto === 0 ? 0 : 11 - resto;
      var resultado = dv === 10 ? `${pis}001${(11 - ((soma + 2) % 11))}` : `${pis}000${dv}`;

      return resultado === cns;
    }
  }

  return false;
}

export function getColors(colorName){


  // Adicionar objetos quando as cores das respectivas cidades foram adicionadas
  // TODO const colorsPMVV= {};
  // TODO const colorsPMCariacica= {};

  const colorsUpcities = {
    button_blue: '#deeeff',
    button_yellow: '#f8e8c3'
  };

  return colorsUpcities[colorName];
}

export function formatFileSize(rawSize) {
  const units = ['B', 'kB', 'MB', 'GB', 'TB'];

  let i = 0;

  if(rawSize !== 0) {
    i = Math.floor(Math.log(rawSize) / Math.log(1024));
  }
  const formatedSize = (rawSize / Math.pow(1024, i));

  return `${formatedSize.toFixed(2)} ${units[i]}`;
}

export function activeCommands() {
  document.addEventListener('keyup', (event) => {
    if (event.altKey && event.key === '1') {
      window.scrollTo(0, 0);
    }
    if (event.altKey && event.key === '2') {
      document.querySelector('.search__open').click();
    }
    if (event.altKey && event.key === '3') {
      window.scrollTo(0, document.body.scrollHeight);
    }
  });
}