import { api } from './main';

export default {
  getSearchData() {
    return api({
      method: 'GET',
      data: null,
      url: '/api/v1/search/categoria/servico'
    });
  }
};
