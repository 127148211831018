const state = {
  errorModal: {},
  showModal: false,
};

const getters = {
  errorModal: (state) => state.errorModal,
  showModal: (state) => state.showModal,
};

const actions = {
  setErrorModal({ commit }, errorModal) {
    commit('SET_ERROR_MODAL', errorModal);
    commit('SHOW_ERROR_MODAL', true);
  },

  cleanErrorModal({ commit }) {
    commit('CLEAN_ERROR_MODAL');
  },

  setShowModal({ commit }, payload) {
    commit('SHOW_ERROR_MODAL', payload);
  },

  closeErrorModal({ commit, dispatch }) {
    commit('SHOW_ERROR_MODAL', false);
    dispatch('cleanErrorModal');
  }
};

const mutations = {
  SET_ERROR_MODAL(state, payload) {
    state.errorModal = payload;
  },

  CLEAN_ERROR_MODAL(state) {
    state.errorModal = {};
    state.showModal = false;
  },

  SHOW_ERROR_MODAL(state, payload) {
    state.showModal = payload;
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
