module.exports = {
  CONTENT: {
    SUBHEADER: {
      city: 'CARIACICA',
    },
    HEADER: {
      title: 'Bem vindo ao agendamento de serviços',
      titleLogged: 'Olá, ',
      subtitle: 'Agende um atendimento na Prefeitura de Cariacica. Escolha o serviço, siga as instruções e leia com atenção as informação para a confirmação.',
    },
    STEPPER: {
        ONE: {
          title: 'Categoria',
          description: 'Selecione a categoria na qual deseja marcar um serviço',
        },
        TWO: {
          title: 'Serviços',
          description: 'Selecione o serviço e os detalhes do atendimento',
        },
        THREE: {
          title: 'Insira os dados',
          description: 'Insira os dados necessários para prosseguir',
        },
        FOUR: {
          title: 'Dados do agendamento',
          description: 'Escolha a data e o horário desejados.',
        },
        FIVE: {
          title: 'Confirmação',
          description: 'Confira seus dados e confirme o seu atendimento',
        },
    },
    HOME: {
      BANNER: {
        description: 'Todos os agendamentos da sua cidade em um único lugar de forma intuitiva, rápida e fácil. Faça seu cadastro para agendar um serviço na Prefeitura de Cariacica.',
      },
      INFOCARDS: {
        FIRST: {
          title: 'Mantenha seu cadastro sempre atualizado',
          description:
            'É muito importante. Assim, você pode garantir o acesso ao agendamento online e realizar o acompanhamento.',
          buttonLabel: 'Atualizar dados'
        },
        SECOND: {
          title: 'Dúvidas sobre o sistema?',
          description:
            'Clique no link abaixo e confira algumas dicas e soluções que separamos para te auxiliar.',
          buttonLabel: 'Preciso de ajuda'
        },
        THIRD: {
          title: 'Avalie o portal e envie sugestões',
          description:
            'Queremos te ouvir para desenvolvermos ainda mais nosso sistema. Participe!',
          buttonLabel: 'Avalie'
        },

      }
    },
    FOOTER: {
      MENU: {
        myAppointments: 'Meus agendamentos',
        appointment: 'Agendar',
        faq: 'Dúvidas',
        account: 'Minha conta',
        shortcut: 'Atalhos de teclado'
      },
      CONTACT: {
        title: 'Em caso de dúvidas:',
        tel: '(27) 3354-5900',
        telLabel: 'Segunda à sexta, de 12:00 às 19:00',
      },
      SOCIAL: {
          facebook: 'https://www.facebook.com/PrefeituraCariacica',
          linkedin: 'https://www.linkedin.com/company/prefeitura-municipal-de-cariacica',
          instagram: 'https://www.instagram.com/prefeituracariacica/',
          youtube: 'https://www.youtube.com/user/prefeituracariacica',
        }
    },
    OTHERS: {
      APP: {
        googlePlay: 'https://play.google.com/store/apps/details?id=br.com.uppersoft.cariacica',
        appleStore: 'https://apps.apple.com/br/app/cariacica-digital/id1613155049',
        title: 'Aplicativo Cariacica On',
        text: 'Baixe o app para interagir ainda mais com a prefeitura de Cariacica. Informações, orientações e serviços em um único lugar de forma intuitiva, rápida e fácil.'
      }
    },
    FAQ: {
      faqItems: [
        {
          id: 0,
          question: 'Posso agendar para outras pessoas no meu cadastro?',
          answer: 'Sim! Após escolher a data e hora do serviço, selecione a opção "Para outra pessoa", insira os dados e confirme.'
        },
        {
          id: 1,
          question: 'Posso cancelar um agendamento?',
          answer: 'Sim! Na tela inicial, clique em ‘consultar/cancelar agendamento’, acesse o agendamento que deseja cancelar e clique no botão vermelho ‘cancelar’. Pronto!'
        },
        {
          id: 2,
          question: 'Fiz o cadastro mas não recebi o e-mail. E agora?',
          answer: 'Caso isso aconteça, existe algumas formas de resolver, são elas:\n\n1. Cheque sua caixa de spam.\n2. Tente realizar o login digitando seu e-mail e senha cadastrados. Será exibida uma tela para validação, clique em reenviar o e-mail, aguarde uns instantes e verifique o recebimento.\n3. Espere alguns minutos, em horário de pico pode demorar mais do que o desejado.\n4. Selecione ‘recuperar senha’ para que um novo e-mail seja enviado.'
        },
        {
          id: 3,
          question: 'Cadastrei meu e-mail errado. O que eu faço?',
          answer: 'Entre em contato com o nosso suporte pelo e-mail agendamento@cariacica.es.gov.br para ser orientado adequadamente.',
        },
      ],
    },
    POLICY: {
      text: 'Esta página do site da Prefeitura de Cariacica usa Cookies Operacionais de Sessão para o funcionamento do sistema, que podem armazenar seus dados pessoais indiretamente. Conforme detalhado no AVISO DE PRIVACIDADE e de acordo com o Art. 5 da LGPD (Lei 13.709/2018) solicitamos seu CONSENTIMENTO para prosseguir. Sua rejeição pode implicar no não funcionamento das tecnologias do site. Você aceita os Cookies de Sessão?',
      ref: 'https://upcities.app/politica-privacidade-cariacica',
    },
  }
};